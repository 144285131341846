@tailwind base;

@tailwind base;

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--primary);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 15px;
}

*::-webkit-scrollbar-track {
    background: var(--primary);
    border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 14px;
    border: 3px solid var(--primary);
}

::-webkit-scrollbar-thumb:hover {
    background: var(--tertiary);
}


@tailwind components;
@tailwind utilities;


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100vh;
    --primary: #ffffff;
    --secondary: #666666;
    --tertiary: #999999;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.load-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #fdba2c;
}

.bar {
    content: "";
    display: inline;
    position: absolute;
    width: 0;
    height: 100%;
    left: 50%;
    text-align: center;
}

.bar:nth-child(1) {
    background-color: #da4733;
    animation: loading 3s linear infinite;
}

.bar:nth-child(2) {
    background-color: #3b78e7;
    animation: loading 3s linear 1s infinite;
}

.bar:nth-child(3) {
    background-color: #fdba2c;
    animation: loading 3s linear 2s infinite;
}

@keyframes loading {
    from {
        left: 50%;
        width: 0;
        z-index: 100;
    }

    33.3333% {
        left: 0;
        width: 100%;
        z-index: 10;
    }

    to {
        left: 0;
        width: 100%;
    }
}

.custom-h {
    height: auto !important;
}

@media screen and (min-width: 1024px) {
    .custom-h {
        height: 100vh !important;
    }
}